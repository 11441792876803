import React, { useState, useRef } from 'react';
import './App.css';

function App() {
    const [entryPrice, setEntryPrice] = useState('');
    const [exitPrice, setExitPrice] = useState('');
    const [orderSize, setOrderSize] = useState('');
    const [realizedLoss, setRealizedLoss] = useState('');
    const [expectedLoss, setExpectedLoss] = useState('');
    const [result, setResult] = useState('');
    const [image, setImage] = useState('');
    const resultRef = useRef(null); // Reference to the result container

    const insideRangeImages = [
        '/apu/inside_range_1.jpg',
        '/apu/inside_range_2.jpg',
        '/apu/inside_range_3.jpg',
        '/apu/inside_range_4.jpg',
    ];

    const outsideRangeImages = [
        '/apu/outside_range_1.jpg',
        '/apu/outside_range_2.jpg',
        '/apu/outside_range_3.jpg',
        '/apu/outside_range_4.jpg',
    ];

    const calculateDeviation = (
        entryPrice,
        exitPrice,
        orderSize,
        realizedLoss,
        expectedLoss
    ) => {
        const limitOrderRebate = -0.00025;
        const marketOrderFee = 0.00075;
        const entryFee = orderSize * entryPrice * limitOrderRebate;
        const exitFee = orderSize * exitPrice * marketOrderFee;
        const adjustedRealizedLoss = realizedLoss + entryFee + exitFee;
        const deviation = ((adjustedRealizedLoss - expectedLoss) / expectedLoss) * 100;
        const roundedDeviation = deviation.toFixed(2);

        if (Math.abs(deviation) <= 10) {
            const randomImage = insideRangeImages[Math.floor(Math.random() * insideRangeImages.length)];
            setImage(randomImage);
            return `${roundedDeviation}% deviation (within acceptable range)`;
        } else {
            const randomImage = outsideRangeImages[Math.floor(Math.random() * outsideRangeImages.length)];
            setImage(randomImage);
            return `${roundedDeviation}% deviation (outside acceptable range)`;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const deviation = calculateDeviation(
            parseFloat(entryPrice),
            parseFloat(exitPrice),
            parseFloat(orderSize),
            parseFloat(realizedLoss),
            parseFloat(expectedLoss)
        );
        setResult(deviation);

        // Use setTimeout to ensure the DOM is updated before scrolling
        setTimeout(() => {
            if (resultRef.current) {
                resultRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    return (
        <div className="App">
            <h1>GM! Welcome to Apus Devi-Calc Tool!</h1>
            <p>GM and Calculate the Deviation of your trade!</p>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="entryPrice">Entry Price:</label>
                    <input
                        id="entryPrice"
                        type="number"
                        value={entryPrice}
                        onChange={(e) => setEntryPrice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exitPrice">Exit Price:</label>
                    <input
                        id="exitPrice"
                        type="number"
                        value={exitPrice}
                        onChange={(e) => setExitPrice(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="orderSize">Order Size (BTC):</label>
                    <input
                        id="orderSize"
                        type="number"
                        step="0.0001"
                        value={orderSize}
                        onChange={(e) => setOrderSize(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="realizedLoss">Realized Loss (USD):</label>
                    <input
                        id="realizedLoss"
                        type="number"
                        step="0.01"
                        value={realizedLoss}
                        onChange={(e) => setRealizedLoss(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="expectedLoss">Expected Loss (USD):</label>
                    <input
                        id="expectedLoss"
                        type="number"
                        step="0.01"
                        value={expectedLoss}
                        onChange={(e) => setExpectedLoss(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Calculate Deviation</button>
            </form>
            {result && (
                <div className="result-container" ref={resultRef}>
                    <div className="result">{result}</div>
                    <img src={image} alt="Result" className="result-image" />
                </div>
            )}
        </div>
    );
}

export default App;
